/* ----------VARIABLES---------- */

:root {
  --blue: #3358ff;
  --orange: #ffa500;
  --white: #ffffff;
  --grey1: #E5E5E5;
  --grey2: #C1C1C1;
  --grey3: #848484;
  --grey4: #262626;
  --black: #000000;
  --shadow: rgba(0, 0, 0, 0.3);
  scroll-behavior: smooth;
}

/* ----------GENERAL---------- */

body {
  margin: 0;
  font-family: Tahoma, Verdana, Geneva, sans-serif;
  overflow-x: hidden;
  font-size: 18px;
}

body.modal-open {
  overflow: hidden;
}

body.dark,
body.dark .modal {
  background-color: var(--grey4);
}

body.dark h1, 
body.dark sub,
body.dark .pagetitle,
body.dark .modal p,
body.dark .modal a,
body.dark .about-content {
  color: var(--white)
}

body.dark .social-logos svg {
  fill: var(--white);
}

main {
  min-height: 60vh;
}

* {
  -ms-overflow-style: none;
  box-sizing: border-box;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  width: none;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: transparent;
  border: none;
}

::-moz-selection {
  color: var(--white);
  background: var(--blue);
}

::selection {
  color: var(--white);
  background: var(--blue);
}

.section,
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  margin-top: 80px;
}

p {
  white-space: pre-line;
  overflow-wrap: break-word;
}

hr {
  width: 100%;
}

h1 {
  font-size: 50px;
  margin: 20px 0;
  text-align: center;
}

h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 10px 0;
}

sub {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
}

button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 18px;
  background-color: rgb(207, 207, 207);
  cursor: pointer;
}

.delete-btn {
  height: 37px;
  width: 37px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*---------- Header ----------*/

header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 5px 5px 0px var(--shadow);
  background-color: var(--blue);
}

menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding: 15px 0;
  width: 100%;
}

menu a {
  padding: 0 20px;
  color: var(--white);
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}

menu a:hover,
menu a:active {
  text-decoration: underline;
}

.btns-container {
  display: flex;
  flex-direction: row;
}

.customize-btn {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
}

.customize-btn .icon {
  pointer-events: none;
}

.customize-btn:first-child .icon {
  margin-right: 5px;
}

/*---------- Home ----------*/

.filter-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.filter-btn.selected {
  background-color: gray;
  color: white;
}

.card-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px 0 0 0;
  width: 100%;
}

.card-grid-column {
  margin: 0 15px;
  background-clip: padding-box;
}

.card {
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  border: 5px solid var(--grey2);
  background-color: var(--white);
}

.card-grid .card img {
  width: 100%;
  cursor: pointer;
}

.card-grid .card img:hover {
  opacity: 0.6;
}

.card-content {
  padding: 5px 10px;
}

.card-content h3 {
  cursor: pointer;
}
.card-content h3:hover {
  color: var(--blue);
}

.card-content p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.shadow-bg {
  background-color: var(--shadow);
  position: fixed;
  padding: 90px 20px 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.modal {
  position: relative;
  margin: 20px;
  top: 0 ;
  padding: 20px;
  background-color: var(--white);
  border: 5px solid var(--black);
  z-index: 50;
  overflow-y: scroll;
  width: 100%;
}

.modal p {
  display: block;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-header a {
  text-decoration: none;
  color: var(--black);
}

.modal-header a:hover {
  text-decoration: underline;
}

.modal-header a svg {
  margin-left: 8px;
}

.sketch-canvas {
  width: 100%;
  min-height: 700px;
  max-height: 100%;
}

.captures-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0 0 0;
  width: 100%;
}

.captures-grid-column {
  margin: 5px;
  background-clip: padding-box;
}

.captures-container {
  display: flex;
  flex-direction: row;
}

.captures-container img {
  width: 100%;
  margin: 5px;
  display: block;
}

.capture {
  margin-bottom: 5px;
}

.capture img:hover{
  cursor: auto;
  opacity: 1 !important;
}

/*---------- About ----------*/

.about-content h3 {
  text-align: center;
}

.about-content hr {
  margin: 30px 0;
}

.social-logos {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
}

.social-logos a {
  max-width: 50px;
  margin: 5px;
}

.social-logos svg {
  width: 100%;
  fill: var(--black);
}

.social-logos svg:hover {
  fill: var(--blue) !important;
}


/*---------- Footer ----------*/

footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  margin-top: 50px;
}

.footer-content {
  background-color: #ffa500;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
}

.footer-legal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--dark-blue);
  width: 100%;
}

.footer-legal p {
  margin: 0;
  text-align: left;
}

.footer-legal .dash {
  margin: 0 10px;
}

.footer-legal a {
  width: 30px;
}

.footer-legal .icon {
  max-width: 25px;
  margin-right: 2px;
}

.creative-commons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*---------- Responsive ----------*/

@media screen and (max-width: 600px) {
  .page {
    padding: 0 30px;
  }

  menu a {
    padding: 0 10px;
  }

  .footer-legal {
    flex-direction: column;
  }

  .footer-legal p {
    text-align: center;
  }

  .card-grid-column {
    margin: 0;
  }

  .captures-container {
    flex-direction: column;
  }
  .captures-container img {
    width: auto;
  }
}

@media screen and (max-width: 400px) {
  .page {
    margin-top: 50px;
  }

  menu a {
    padding: 0 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .customize-btn {
    font-size: 16px;
    padding: 3px 5px;
    height: 30px;
  }

  footer {
    margin-top: 10px;
  }
}
